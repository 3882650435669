import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import './styles.scss'

const ImageStrip = loadable(() => import('/src/components/PageBuilder/layouts/standard/ImageStrip'))

const FeaturedIn = ({ backgroundColour }) => {
    const globalQuery = useStaticQuery(
        graphql`
            query {
                component: allWp {
                    nodes {
                        globalBlocks {
                            featuredIn {
                                fieldGroupName
                                featuredIn {
                                    addTitleArea
                                    title
                                    imageList {
                                        image {
                                            id
                                            localFile {
                                                childImageSharp {
                                                    gatsbyImageData(width: 100)
                                                }
                                            }
                                            altText
                                            title
                                        }
                                        link {
                                            target
                                            title
                                            url
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `
    )

    const globalData = globalQuery.component.nodes

    return globalData.map((node, index) => {
        let componentData = node.globalBlocks.featuredIn.featuredIn

        const titleArea = {
            addTitleArea: componentData.addTitleArea,
            titlePosition: 'centered',
            title: componentData.title
        }

        return (
            <ImageStrip
                key={index}
                backgroundColour={backgroundColour}
                size={'sm'}
                titleArea={titleArea}
                imageList={componentData.imageList}
                className={'c-featured-in'}
            />
        )
    })
}

FeaturedIn.propTypes = {
    backgroundColour: PropTypes.oneOf(['light', 'alt', 'dark', 'gradient']).isRequired
}

FeaturedIn.defaultProps = {
    backgroundColour: 'light'
}

export default FeaturedIn

export const query = graphql`
    fragment FeaturedInForPageBuilder on WpPage_Acfpagebuilder_Layouts_FeaturedIn {
        fieldGroupName
        backgroundColour
    }
`
